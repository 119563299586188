/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import {
  ReactElement,
  ReactNode,
  MouseEventHandler,
  CSSProperties,
} from 'react';

interface Props {
  association: string;
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLLabelElement>;
  style?: CSSProperties;
}

const IndependentLabel = ({
  association,
  children,
  onClick,
  style,
}: Props): ReactElement => (
  <label htmlFor={association} onClick={onClick} style={style}>
    {children}
    <input id={association} type="hidden" />
  </label>
);

IndependentLabel.defaultProps = {
  onClick: undefined,
  style: undefined,
};

export default IndependentLabel;
