import { useState, useCallback, ReactElement, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Input, closeModal, Loading } from '../../../Components';
import validarErrosYup from '../../../utils/validar-erros-yup';
import api from '../../../utils/api';
import {
  showErrorForDev,
  exibirToastError,
  exibirToastErrorCatch,
} from '../../../utils/alertas';
import { StateRedux } from '../../../store/typeRedux';
import getElementoPorId from '../../../utils/getElementoPorId';
import { EnderecoCadastro } from '../../../types/Endereco';

interface Props {
  bucarEnderecosDeEntrega: () => void;
}

const CadastrarEnderecoEntrega = ({
  bucarEnderecosDeEntrega,
}: Props): ReactElement => {
  const [loading, setLoading] = useState<boolean>(false);
  const formRef = useRef<FormHandles>(null);

  const { user } = useSelector((state: StateRedux) => state);

  const validarDadosForm = async (data: EnderecoCadastro) => {
    formRef.current?.setErrors({});

    const schema = Yup.object().shape({
      cep: Yup.string().required('cep é obrigatório'),
      logradouro: Yup.string().required('Logadouro é obrigatório'),
      numero: Yup.string().required('Número é obrigatório'),
      bairro: Yup.string().required('Bairro é obrigatório'),
      cidade: Yup.string().required('Cidade é obrigatória'),
      estado: Yup.string().required('Estado é obrigatório'),
    });

    await schema.validate(data, {
      abortEarly: false,
    });
  };

  const limparFormulario = () => {
    formRef.current?.setData({
      cep: '',
      logradouro: '',
      numero: '',
      bairro: '',
      cidade: '',
      estado: '',
    });
  };

  const cadastrarEndereco = useCallback(
    async (data: EnderecoCadastro) => {
      if (!user) {
        showErrorForDev('Usuário deslogado');
        return;
      }

      try {
        await validarDadosForm(data);
        setLoading(true);
        await api.cadastrarEnderecoUsuario({
          ...data,
          usuariosId: user.usuariosId,
        });

        bucarEnderecosDeEntrega();
        closeModal('modal-endereco-cadastro');
        setTimeout(() => {
          getElementoPorId('anchor-modal-endereco-entrega')?.click();
        }, 800);
        limparFormulario();
      } catch (error) {
        if (error?.response) {
          exibirToastErrorCatch(error);
          return;
        }

        formRef.current?.setErrors(validarErrosYup(error));
      } finally {
        setLoading(false);
      }
    },
    [user], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const pesquisarEnderecoPorCep = async () => {
    try {
      const dataForm = formRef.current?.getData();
      const cep = dataForm?.cep.replace(/\D/, '');
      if (!cep) {
        exibirToastError('Digite o cep para buscar endereço');
        return;
      }

      if (cep.length < 8) {
        return;
      }

      const { data } = await api.getEnderecoPorCep(cep);
      formRef.current?.setData({ ...data });
    } catch (error) {
      exibirToastErrorCatch(error);
    }
  };

  return (
    <div
      className="content"
      style={{ backgroundColor: '#FFFFFF', padding: 20 }}
    >
      <Form
        ref={formRef}
        style={{ height: 'fit-content', marginTop: 15 }}
        onSubmit={cadastrarEndereco}
      >
        <div className="sign-in-wrapper">
          <div style={{ marginBottom: 20 }}>
            <div
              className="hideShowPassword-wrapper"
              style={{
                position: 'relative',
                display: 'block',
                verticalAlign: 'baseline',
                margin: 0,
              }}
            >
              <Input
                type="text"
                name="cep"
                className="form-control"
                placeholder="CEP"
                onChange={pesquisarEnderecoPorCep}
              />
              <button
                type="button"
                aria-label="Pesquisa"
                title="Pesquisar cep"
                className="my-toggle"
                aria-pressed="false"
                onClick={pesquisarEnderecoPorCep}
                style={{
                  position: 'absolute',
                  right: 0,
                  top: '50%',
                  marginTop: -15,
                }}
              >
                Buscar endereço
              </button>
            </div>
          </div>
          <div style={{ marginBottom: 20 }}>
            <Input
              type="text"
              name="logradouro"
              className="form-control"
              placeholder="Logradouro"
            />
          </div>
          <div style={{ marginBottom: 20 }}>
            <Input
              type="text"
              name="numero"
              className="form-control"
              placeholder="Número"
            />
          </div>
          <div style={{ marginBottom: 20 }}>
            <Input
              type="text"
              name="complemento"
              className="form-control"
              placeholder="Complemento"
            />
          </div>
          <div style={{ marginBottom: 20 }}>
            <Input
              type="text"
              name="bairro"
              className="form-control"
              placeholder="Bairro"
            />
          </div>
          <div style={{ marginBottom: 20 }}>
            <Input
              type="text"
              name="cidade"
              className="form-control"
              placeholder="Cidade"
            />
          </div>
          <div style={{ marginBottom: 20 }}>
            <Input
              type="text"
              className="form-control"
              name="estado"
              maxLength={2}
              placeholder="Estado"
            />
          </div>
          <div style={{ marginBottom: 20 }}>
            <Input
              type="text"
              name="pontoReferencia"
              className="form-control"
              placeholder="Ponto de referência"
            />
          </div>
          <div style={{ marginBottom: 20 }}>
            <Input
              type="text"
              name="nome"
              className="form-control"
              placeholder="Título - Ex: Casa, Trabalho..."
            />
          </div>
          <button type="submit" className="btn_1 full-width mb_5">
            Salvar endereço
          </button>
        </div>
      </Form>
      <Loading show={loading} />
    </div>
  );
};

export default CadastrarEnderecoEntrega;
