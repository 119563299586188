import React, { ReactElement, CSSProperties } from 'react';
import { URL } from '../../../utils/constantes';
import { Produto } from '../../../types/Produto';

interface Props {
  produto: Produto;
  style?: CSSProperties;
}

const getImagem = (url: string): string => (url ? `${URL}imagens/${url}` : '');

const ImagemDoProduto = ({ produto, style }: Props): ReactElement | null => {
  if (!produto.imagem?.url) {
    return null;
  }

  return (
    <figure style={style}>
      <img src={getImagem(produto.imagem.url)} alt="thumb" className="lazy" />
    </figure>
  );
};

ImagemDoProduto.defaultProps = {
  style: {},
};

export default ImagemDoProduto;
