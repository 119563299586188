import {
  useEffect,
  useState,
  ReactElement,
  ChangeEvent,
  MouseEvent,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { Produto } from '../../types/Produto';
import maskMoney from '../../utils/mask-money';
import Loading from '../../Components/Loading';
import SelecaoEndereco from './Components/SelecaoEndereco';
import SelecaoVoucher from './Components/SelecaoVoucher';
import SelecaoFormaDePagamento from './Components/SelecaoFormaDePagamento';
import DetalhesDoPedido from './Components/DetalhesDoPedido';
import CadastrarEnderecoEntrega from './Components/CadastrarEnderecoEntrega';
import { Endereco } from '../../types/Endereco';
import {
  FormGroup,
  InputMaskCpfCnpj,
  InputMaskTelefone,
  Modal,
  Button,
  Anchor,
} from '../../Components';
import api from '../../utils/api';
import alertas, {
  exibirToastSucesso,
  exibirToastError,
  exibirToastErrorCatch,
  showErrorForDev,
} from '../../utils/alertas';
import { ExceptionError } from '../../utils';
import { StateRedux } from '../../store/typeRedux';
import { FormaPagamento } from '../../types/Pagamento';
import { Voucher } from '../../types/Voucher';

const Carrinho = (): ReactElement => {
  const [carrinhoAtivo, setCarrinhoAtivo] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [produtos, setProdutos] = useState<Produto[]>([]);
  const [taxa, setTaxa] = useState<number>(0);
  const [subTotal, setSubTotal] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [enderecosId, setEnderecosId] = useState<number>(0);
  const [enderecos, setEnderecos] = useState<Endereco[]>([]);
  const [enderecoEscolhido, setEnderecoEscolhido] = useState<string>(
    'Escolha um local de entrega',
  );
  const [formaPagamento, setFormaPagamento] = useState<FormaPagamento | null>(
    null,
  );
  const [troco, setTroco] = useState<number>(0);
  const [voucher, setVoucher] = useState<Voucher | null>(null);
  const [cpfNota, setCpfNota] = useState<string | null>(null);
  const [telefone, setTelefone] = useState<string>('');
  const [erroCpfCnpj, setErroCpfCnpj] = useState<string | null>(null);
  const [telefoneCadastro, setTelefoneCadastro] = useState<string>('');

  const { empresa, pedidosId, tokenUser, pedidosProdutos, user } = useSelector(
    (state: StateRedux) => state,
  );

  const dispatch = useDispatch();

  const limparCarrinho = () => {
    setEnderecoEscolhido('Escolha um local de entrega');
    setEnderecos([]);
    setEnderecosId(0);
    setTaxa(0);
    setSubTotal(0);
    setTotal(0);
    setVoucher(null);
    setFormaPagamento(null);
    setCpfNota(null);
    setTelefone('');

    dispatch({
      type: 'ITENS_NO_CARRINHO',
      pedidosProdutos: null,
    });
  };

  const getValorDescontoVoucher = () => {
    if (!voucher || !voucher.codigo) {
      return 0.0;
    }

    const { valor } = voucher;

    if (!voucher.codigo.trim()) {
      return 0.0;
    }

    if (voucher.tipo === '%') {
      const value = (valor / 100) * subTotal;
      return value < 0 ? value * -1 : value;
    }

    return valor < 0 ? valor * -1 : valor;
  };

  const validarCpfCnpj = (value: string) => {
    const documento = value.replace(/\.|-/g, '');
    if (documento.length && documento.length <= 11 && !cpf.isValid(documento)) {
      setErroCpfCnpj('cpf Inválido');
      return;
    }

    if (documento.length > 11 && !cnpj.isValid(documento)) {
      setErroCpfCnpj('cnpj Inválido');
      return;
    }

    setErroCpfCnpj(null);
  };

  const atualizarInformacoesDoCarrinho = async () => {
    try {
      if (!pedidosId || !tokenUser) {
        setProdutos([]);
        setTaxa(0);
        setSubTotal(0);
        setVoucher(null);
        return;
      }

      const { data: produtosCarrinho } = await api.buscarProdutosDoCarrinho(
        pedidosId,
      );

      const { data: contatos } = await api.buscarContatosUsuario();

      if (produtosCarrinho.produtos.length) {
        setSubTotal(produtosCarrinho.valor);
        if (voucher && produtosCarrinho.valor < voucher.valorMinimo) {
          setVoucher(null);
        }
      }

      if (contatos.length > 0) {
        const { numero, ddd } = contatos[0];
        setTelefone(`(${ddd}) ${numero}`);
      }

      setProdutos(produtosCarrinho.produtos);
    } catch (error) {
      exibirToastErrorCatch(error);
    }
  };

  const cadastrarNumeroWhatsapp = async () => {
    if (!carrinhoAtivo) {
      return;
    }

    const numero = telefoneCadastro.replace(/\D/g, '');

    if (!numero.length) {
      throw ExceptionError(
        'Número de whatsapp é necessário para envio de status',
        401,
      );
    }

    if (numero.length < 10) {
      throw ExceptionError('Por favor digite um número válido', 401);
    }

    const params = {
      empresasId: empresa?.id,
      usuariosId: user?.usuariosId,
      telefone: numero,
      nome: 'Contato',
      verificarWhatsapp: true,
    };

    await api.adicionarContato(params);

    exibirToastSucesso('Número cadastrado com sucesso!');

    setTelefone(telefoneCadastro);
  };

  const finalizarPedido = async () => {
    try {
      if (!pedidosId || !tokenUser || !user) {
        alertas.exibirError(
          'Atenção',
          'Por favor faça login para continuar comprando',
        );
        return;
      }

      if (!telefone) {
        await cadastrarNumeroWhatsapp();
      }

      const pedido = {
        id: pedidosId,
        valor: subTotal,
        etapasId: 2,
        usuariosId: user.usuariosId,
        formasPagamentoId: formaPagamento?.id || 0,
        troco,
        cpfNota,
        voucher: voucher?.codigo || null,
        enderecosId,
        valorVoucher: getValorDescontoVoucher(),
        taxaEntrega: taxa,
        origem: 'web',
      };

      setLoading(true);
      await api.enviarPedido(pedidosId, pedido);

      dispatch({
        type: 'PEDIDO_ATIVO',
        pedidosID: null,
      });

      exibirToastSucesso('Pedido enviado!');
      limparCarrinho();
    } catch (error) {
      exibirToastErrorCatch(error);
    } finally {
      setLoading(false);
    }
  };

  const alterarProdutoDiretoDoCarrinho = async (
    id: number,
    quantidade: number,
  ) => {
    try {
      if (!pedidosId) {
        showErrorForDev('Não encontrei o id do pedido');
        return;
      }

      await api.updateProdutoCarrinho(pedidosId, {
        id,
        quantidade,
      });
      atualizarInformacoesDoCarrinho();
    } catch (error) {
      exibirToastErrorCatch(error);
    }
  };

  const removerProdutoDoCarrinho = async (id: number) => {
    try {
      if (!pedidosId) {
        showErrorForDev('Não encontrei o id do pedido');
        return;
      }

      await api.deleteProdutoCarrinho(pedidosId, { id });
      limparCarrinho();
    } catch (error) {
      exibirToastErrorCatch(error);
    }
  };

  const bucarEnderecosDeEntrega = async () => {
    try {
      const { data } = await api.buscarEnderecosDeEntrega();
      setEnderecos(data);
    } catch (error) {
      exibirToastError(
        get(
          error,
          'response.data.message',
          'Algo deu errado ao buscar endereços',
        ),
      );
    }
  };

  useEffect(() => {
    setTroco(0);
  }, [enderecoEscolhido]);

  useEffect(() => {
    if (!pedidosId || !tokenUser) {
      return;
    }

    bucarEnderecosDeEntrega();
  }, [pedidosId, tokenUser]);

  useEffect(() => {
    setCarrinhoAtivo(!!produtos.length);
  }, [subTotal, produtos]);

  useEffect(() => {
    const preTotal = subTotal + taxa;
    setTotal(preTotal);

    if (voucher?.valor) {
      setTotal(preTotal - getValorDescontoVoucher());
    }
  }, [taxa, subTotal, voucher]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    atualizarInformacoesDoCarrinho();
  }, [pedidosId, tokenUser, pedidosProdutos]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="col-lg-4" id="sidebar_fixed">
        <Modal
          title="Endereço de entrega"
          modalId="modal-endereco-cadastro"
          style={{ zIndex: 999999999 }}
          lg
        >
          <CadastrarEnderecoEntrega
            bucarEnderecosDeEntrega={bucarEnderecosDeEntrega}
          />
        </Modal>
        <div
          className="theiaStickySidebar"
          style={{
            paddingTop: 0,
            paddingBottom: 1,
            position: 'static',
            transform: 'none',
            top: 0,
            left: 1187.5,
          }}
        >
          <div className="box_order mobile_fixed">
            <div className="head">
              <h3>Carrinho</h3>
              <Anchor
                href="#"
                className="close_panel_mobile"
                onClick={(e: MouseEvent) => e.preventDefault()}
              >
                <i className="icon_close" />
              </Anchor>
            </div>
            <div className="main">
              <DetalhesDoPedido
                produtos={produtos}
                alterarProdutoDiretoDoCarrinho={alterarProdutoDiretoDoCarrinho}
                removerProdutoDoCarrinho={removerProdutoDoCarrinho}
              />

              <ul className="clearfix">
                <li>
                  Taxa de entrega<span>{maskMoney(taxa)}</span>
                </li>
                <li>
                  Subtotal<span>{maskMoney(subTotal)}</span>
                </li>
                {voucher && (
                  <li>
                    Desconto<span>{maskMoney(getValorDescontoVoucher())}</span>
                  </li>
                )}
                <li className="total">
                  Total<span>{maskMoney(total)}</span>
                </li>
              </ul>
              <div
                className="opt_order"
                style={{ paddingTop: 10, paddingBottom: 10 }}
              >
                <div className="flex justify-content-space-between align-items-center">
                  <div className="flex flex-direction-column">
                    <span>Valor mínimo do pedido</span>
                    <small>Taxa de entrega não inclusa!</small>
                  </div>
                  <div>
                    <small style={{ fontSize: 13, fontWeight: 600 }}>
                      {maskMoney(empresa?.valorMinimoPedido || 0)}
                    </small>
                  </div>
                </div>
              </div>

              <SelecaoEndereco
                enderecoEscolhido={enderecoEscolhido}
                setEnderecoEscolhido={setEnderecoEscolhido}
                enable={carrinhoAtivo}
                enderecos={enderecos}
                setEnderecosId={setEnderecosId}
                setTaxa={setTaxa}
              />

              <SelecaoVoucher
                enable={carrinhoAtivo}
                totalPedido={total}
                selectVoucher={setVoucher}
                usuariosId={user?.usuariosId || 0}
                pedidosId={pedidosId}
                setLoading={setLoading}
              />

              <SelecaoFormaDePagamento
                enable={carrinhoAtivo}
                setFormaPagamento={setFormaPagamento}
                formaPagamento={formaPagamento}
                valorTotal={total}
                troco={troco}
                setTroco={setTroco}
              />

              <FormGroup
                style={{ pointerEvents: carrinhoAtivo ? 'visible' : 'none' }}
              >
                <InputMaskCpfCnpj
                  id="cpf-cnpj-nota"
                  className="form-control"
                  placeholder="CPF/CNPJ na nota"
                  value={cpfNota || ''}
                  onInput={(e: ChangeEvent<HTMLInputElement>) => {
                    const { value } = e.target;
                    setCpfNota(value);
                    validarCpfCnpj(value);
                  }}
                />
                <span
                  className={`fade alert alert-danger ${
                    erroCpfCnpj ? 'show' : ''
                  }`}
                  style={{ color: 'rgb(244, 81, 108)' }}
                >
                  {erroCpfCnpj}
                </span>
              </FormGroup>

              {telefone ? (
                <FormGroup style={{ pointerEvents: 'none' }}>
                  <input
                    className="form-control"
                    defaultValue={telefone}
                    placeholder="Telefone de contato"
                  />
                </FormGroup>
              ) : (
                <FormGroup>
                  <div
                    className="hideShowPassword-wrapper"
                    style={{
                      position: 'relative',
                      display: 'block',
                      verticalAlign: 'baseline',
                      margin: 0,
                    }}
                  >
                    <InputMaskTelefone
                      className="form-control"
                      style={{
                        pointerEvents: carrinhoAtivo ? 'visible' : 'none',
                      }}
                      id="input-tel-carrinho"
                      placeholder="Número do Whatsapp"
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setTelefoneCadastro(e.target.value);
                      }}
                    />
                    <button
                      type="button"
                      className="my-toggle"
                      title="Cadastrar número"
                      style={{
                        position: 'absolute',
                        right: 0,
                        top: '50%',
                        marginTop: '-15px',
                      }}
                      onClick={cadastrarNumeroWhatsapp}
                    >
                      Cadastrar
                    </button>
                  </div>
                </FormGroup>
              )}

              <FormGroup>
                <div style={{ height: 5 }} />
              </FormGroup>
              <div className="btn_1_mobile">
                <Button
                  title="Enviar pedido"
                  onClick={finalizarPedido}
                  disabled={!carrinhoAtivo || !!erroCpfCnpj || !formaPagamento}
                />
              </div>
            </div>
          </div>
          <div
            className="btn_reserve_fixed"
            style={{ opacity: !produtos.length ? 0 : 1 }}
          >
            <Anchor
              className="btn_1 gradient full-width"
              onClick={(e: MouseEvent) => e.preventDefault()}
            >
              Ver carrinho
            </Anchor>
          </div>
        </div>
      </div>
      <Loading show={loading} />
    </>
  );
};

export default Carrinho;
