import { ReactElement } from 'react';
import maskMoney from '../../../utils/mask-money';
import { Produto } from '../../../types/Produto';

interface Props {
  produtos: Produto[];
  alterarProdutoDiretoDoCarrinho: (id: number, quantida: number) => void;
  removerProdutoDoCarrinho: (id: number) => void;
}

const DetalhesDoPedido = ({
  produtos,
  alterarProdutoDiretoDoCarrinho,
  removerProdutoDoCarrinho,
}: Props): ReactElement => (
  <>
    {!produtos.length ? (
      <div className="flex justify-content-center">
        <img
          src="https://www.babymumz.com/resources/assets/front/img/cartEmpty.png"
          alt="Carrinho vazio"
          style={{ height: 100, marginBottom: 20 }}
        />
      </div>
    ) : (
      <ul className="clearfix">
        {produtos.map(({ id, quantidade, nome, preco, complementos }) => (
          <li key={id}>
            <div className="flex justify-content-space-between">
              <div className="flex justify-content-center">
                <div className="flex justify-content-center">
                  <button
                    type="button"
                    style={{
                      border: 'none',
                      outline: 'none',
                      backgroundColor: 'transparent',
                    }}
                    onClick={() => {
                      if (quantidade > 1) {
                        alterarProdutoDiretoDoCarrinho(id, quantidade - 1);
                        return;
                      }

                      removerProdutoDoCarrinho(id);
                    }}
                  >
                    <i
                      style={{ color: 'rgb(245 92 117)' }}
                      className={
                        quantidade === 1 ? 'icon_trash' : 'icon_minus_alt2'
                      }
                    />
                  </button>
                  <span style={{ fontSize: 15 }}>{quantidade}</span>
                  <button
                    type="button"
                    style={{
                      border: 'none',
                      outline: 'none',
                      backgroundColor: 'transparent',
                    }}
                    onClick={() =>
                      alterarProdutoDiretoDoCarrinho(id, quantidade + 1)
                    }
                  >
                    <i className="icon_plus_alt2" style={{ color: '#6c6' }} />
                  </button>
                </div>
                <h6 style={{ margin: 0 }}>{nome}</h6>
              </div>
              <span>{maskMoney(preco)}</span>
            </div>
            <div className="flex flex-direction-column justify-content-between">
              {complementos.map(complemento => (
                <div
                  key={complemento.id}
                  className="flex justify-content-space-between"
                >
                  <span
                    style={{
                      color: '#777',
                      marginBottom: 3,
                      fontSize: 13,
                      marginLeft: 15,
                    }}
                  >
                    {`${quantidade * complemento.quantidade}x `}
                    {complemento.nome}
                  </span>
                  <span
                    style={{
                      color: 'rgb(119, 119, 119)',
                      fontSize: 13,
                    }}
                  >
                    {maskMoney(
                      quantidade * (complemento.quantidade * complemento.preco),
                    )}
                  </span>
                </div>
              ))}
            </div>
          </li>
        ))}
      </ul>
    )}
  </>
);

export default DetalhesDoPedido;
