export const themeDefault = {
  purple: '#38c3e8',
  matteBlue: '#394865',
  metteDanger: '#DD6B55',
  matteYellow: '#ccc7b1',
  gray: '#999',
  success: '#34bfa3',
  warning: '#ffb822',
  danger: '#E02041',
  info: '#36a3f7',
  primary: '#5867dd',
  secondary: '#eaeaea',
  brand: '#716aca',
};

const colors = {
  ...themeDefault,
};

export default colors;
