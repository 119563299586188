import { ReactElement } from 'react';
import IndependentLabel from '../../../Components/IndependentLabel';
import { Dropdown, Modal, closeModal, Anchor } from '../../../Components';
import { Endereco } from '../../../types/Endereco';

interface Props {
  enderecoEscolhido: string;
  setEnderecoEscolhido: (value: string) => void;
  setEnderecosId: (value: number) => void;
  setTaxa: (value: number) => void;
  enable: boolean;
  enderecos: Endereco[];
}

const SelecaoEndereco = ({
  enderecoEscolhido,
  setEnderecoEscolhido,
  setEnderecosId,
  setTaxa,
  enable,
  enderecos,
}: Props): ReactElement => (
  <>
    <Dropdown
      style={{
        marginBottom: 10,
        pointerEvents: enable ? 'visible' : 'none',
      }}
    >
      <Anchor
        className="modal-from-cart flex justify-content-space-between"
        data-toggle="modal"
        data-target="#modal-endereco-entrega"
        id="anchor-modal-endereco-entrega"
        style={{ color: enable ? '' : '#999' }}
      >
        {enderecoEscolhido}
        <i className="icon_pin_alt" />
      </Anchor>
    </Dropdown>
    <Modal
      title="Endereço de entrega"
      modalId="modal-endereco-entrega"
      lg
      style={{ zIndex: 999999999 }}
    >
      <>
        <div className="content" style={{ backgroundColor: '#FFFFFF' }}>
          <div className="radio_select">
            <ul>
              <li style={{ width: '90%' }}>
                <button
                  type="button"
                  className="modal-from-cart btn_1 gradient full-width mb_5"
                  data-toggle="modal"
                  data-target="#modal-endereco-cadastro"
                  style={{
                    backgroundColor: 'rgb(193, 14, 14)',
                    pointerEvents: 'visible',
                  }}
                  onClick={() => {
                    closeModal('modal-endereco-entrega');
                  }}
                >
                  ADICIONAR ENDEREÇO
                </button>
              </li>
              {enderecos.map(endereco => (
                <li key={endereco.id} style={{ width: '90%' }}>
                  <IndependentLabel
                    association="endereco"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setEnderecosId(endereco.id);
                      setEnderecoEscolhido(
                        `${endereco.logradouro} - ${endereco.numero} ${endereco.cidade}`,
                      );
                      setTaxa(endereco.valor);
                      closeModal('modal-endereco-entrega');
                    }}
                  >
                    {endereco.nome || endereco.cep}
                    <em style={{ color: '#4c68df' }}>
                      {`${endereco.logradouro} - ${endereco.numero} ${endereco.cidade}`}
                    </em>
                  </IndependentLabel>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div />
      </>
    </Modal>
  </>
);

export default SelecaoEndereco;
