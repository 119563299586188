/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, ReactElement, InputHTMLAttributes, FC } from 'react';
import inputMask from 'inputmask';
import getInputPorId from '../../utils/getInputPorId';

type InputProps = InputHTMLAttributes<HTMLInputElement>;

const InputMaskTelefone: FC<InputProps> = ({ ...rest }): ReactElement => {
  const telefone = () =>
    inputMask({ mask: ['(99) 9999-9999', '(99) 99999-9999'] });

  useEffect(() => {
    telefone().mask(getInputPorId(rest.id || ''));
  }, [rest.id]);

  return <input {...rest} />;
};

export default InputMaskTelefone;
