const getInputPorId = (id: string): HTMLInputElement => {
  const input = document.getElementById(id);
  if (!input) {
    throw new TypeError(`#${id} não encontrado no documento.`);
  }

  if (input instanceof HTMLInputElement) {
    return input;
  }

  throw new TypeError(`#${id} não é um input.`);
};

export default getInputPorId;
