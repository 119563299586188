/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { ChangeEvent, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Empresa, ContatoEmpresa } from '../types/Empresa';
import { Categoria } from '../types/Categoria';
import { Endereco } from '../types/Endereco';
import { FormaPagamento } from '../types/Pagamento';

interface Itens {
  empresa: Empresa | null;
  categorias: Categoria[];
  endereco: Endereco | null;
  contato: ContatoEmpresa[];
  formasPagamento: FormaPagamento[];
}

const Footer = ({
  empresa,
  categorias,
  endereco,
  contato,
  formasPagamento,
}: Itens): ReactElement => (
  <>
    <div className="wave footer" />
    <div className="container margin_60_40 fix_mobile">
      <div className="row">
        <div className="col-lg-3 col-md-6">
          <h3
            className="collapsed"
            data-target="#collapse_1"
            aria-expanded="false"
            data-toggle="collapse"
          >
            Quick Links
          </h3>
          <div className="collapse dont-collapse-sm links" id="collapse_1">
            <ul>
              <li>
                <a
                  href="https://plom.com.br/termos-uso-estabelecimento"
                  target="_blank"
                  rel="noreferrer"
                >
                  Termos e condições
                </a>
              </li>
              <li>
                <a
                  href="https://plom.com.br/politica-privacidade"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacidade
                </a>
              </li>
              <li>
                <a
                  href="https://maxscalla.com.br/portfolio/plom/"
                  target="_blank"
                  rel="noreferrer"
                >
                  © Max Scalla
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <h3
            className="collapsed"
            data-target="#collapse_2"
            aria-expanded="false"
            data-toggle="collapse"
          >
            Categorias
          </h3>
          <div className="collapse dont-collapse-sm links" id="collapse_2">
            <ul>
              {categorias.map(categoria => (
                <li key={categoria.id}>
                  <a href={`#section-${categoria.nome.replace(/\s/g, '')}`}>
                    {categoria.nome}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <h3
            className="collapsed"
            data-target="#collapse_3"
            aria-expanded="false"
            data-toggle="collapse"
          >
            Contatos
          </h3>
          <div className="collapse dont-collapse-sm contacts" id="collapse_3">
            <ul>
              <li>
                <i className="icon_house_alt" />
                {`${endereco?.logradouro}, ${endereco?.numero}, ${endereco?.bairro}`}
                <br />
                {`${endereco?.cidade} - ${endereco?.estado}`}
              </li>
              {contato.map(item => (
                <li key={item.id}>
                  <i className="icon_mobile" />
                  {`(${item.ddd}) ${item.numero}`}
                </li>
              ))}
              <li>
                <i className="icon_mail_alt" />
                <Link to="#0">{empresa?.email}</Link>
              </li>
            </ul>
          </div>
        </div>
        {/* <div className="col-lg-3 col-md-6">
          <h3
            className="collapsed"
            data-target="#collapse_4"
            aria-expanded="false"
            data-toggle="collapse"
          >
            Receba as novidades
          </h3>
          <div className="collapse dont-collapse-sm" id="collapse_4">
            <div id="newsletter">
              <div id="message-newsletter" />
              <form
                name="newsletter_form"
                id="newsletter_form"
                onSubmit={(e: ChangeEvent<HTMLFormElement>) => {
                  e.preventDefault();
                }}
              >
                <div className="form-group">
                  <input
                    type="email"
                    name="email_newsletter"
                    id="email_newsletter"
                    className="form-control"
                    placeholder="Seu email"
                  />
                  <button type="submit" id="submit-newsletter">
                    <i className="arrow_carrot-right" />
                  </button>
                </div>
              </form>
            </div>
            <div className="follow_us">
              <h5>Nos acompanhe</h5>
              <ul>
                <li>
                  <Link to="#0">
                    <img src={imgFacebook} alt="" className="lazy" />
                  </Link>
                </li>
                <li>
                  <Link to="#0">
                    <img src={imgInstagram} alt="" className="lazy" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
      </div>
      <hr />
      <div className="row add_bottom_25">
        <div className="col-lg-12">
          <ul className="footer-selector clearfix">
            {/* <li>
              <div className="styled-select lang-selector">
                <select>
                  <option value="English" selected>
                    English
                  </option>
                  <option value="French">French</option>
                  <option value="Spanish">Spanish</option>
                  <option value="Russian">Russian</option>
                </select>
              </div>
            </li>
            <li>
              <div className="styled-select currency-selector">
                <select>
                  <option value="US Dollars" selected>
                    US Dollars
                  </option>
                  <option value="Euro">Euro</option>
                </select>
              </div>
            </li> */}
            <li>
              {formasPagamento.map(formaPagamento => (
                <img
                  key={formaPagamento.id}
                  src={formaPagamento.imagem}
                  data-src={formaPagamento.imagem}
                  alt={formaPagamento.nome}
                  title={formaPagamento.nome}
                  width="55"
                  height="35"
                  className="lazy"
                />
              ))}
            </li>
          </ul>
        </div>
        {/* <div className="col-lg-6">
          <ul className="additional_links">
            <li>
              <Link to="#0">Termos e condições</Link>
            </li>
            <li>
              <Link to="#0">Privacidade</Link>
            </li>
            <li>
              <span>© Max Scalla</span>
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  </>
);

export default Footer;
