const files: string[] = [
  // CUSTOM
  'js/custom.js',
];

const autoloadFilesJs = (): void => {
  files.forEach(file => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = file;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  });
};

export default autoloadFilesJs;
