import { useState, useEffect, ReactElement, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import LoadSkeletonInicial from './Components/LoadSkeletonInicial';
import Header from './Components/Header';
import Apresentacao from './Pages/Apresentacao';
import ModalCenter from './Components/ModalCenter';
import Menu from './Components/Menu';
import Cardapio from './Pages/Cardapio';
import Footer from './Components/Footer';
import api from './utils/api';
import { exibirToastError } from './utils/alertas';
import { EmpresaIdParams } from './types';
import { Empresa, ContatoEmpresa } from './types/Empresa';
import { Categoria } from './types/Categoria';
import { Endereco } from './types/Endereco';
import { FormaPagamento } from './types/Pagamento';
import { HorarioFuncionamento } from './types/HorarioFuncionamento';
import { Avaliacao } from './types/Avaliacoes';
import autoloadFilesJs from './utils/autoload-files-js';

const App = (): ReactElement => {
  const { identifier }: EmpresaIdParams = useParams();
  const [empresa, setEmpresa] = useState<Empresa | null>(null);
  const [endereco, setEndereco] = useState<Endereco | null>(null);
  const [contatoEmpresa, setContatoEmpresa] = useState<ContatoEmpresa[]>([]);
  const [pagamentos, setPagamentos] = useState<FormaPagamento[]>([]);
  const [categorias, setCategorias] = useState<Categoria[]>([]);
  const [categoriasFiltrada, setCategoriasFiltrada] = useState<Categoria[]>([]);
  const [funcionamento, setFuncionamento] = useState<HorarioFuncionamento[]>(
    [],
  );
  const [avaliacao, setAvaliacao] = useState<Avaliacao | null>(null);
  const [porPagina, setPorPagina] = useState<number>(10);

  const dispatch = useDispatch();

  const getEnderecoEmpresa = async () => {
    try {
      const { data } = await api.getEnderecoDoEstabeleciomento();
      setEndereco(data);

      dispatch({
        type: 'ENDERECO_EMPRESA',
        endereco: data,
      });
    } catch (error) {
      exibirToastError(
        get(
          error,
          'response.data.message',
          'Não encontrei o endereço da empresa',
        ),
      );
    }
  };

  const getContatosEmpresa = async (empresasId: number) => {
    try {
      const { data } = await api.getContatoEmpresa(empresasId);
      setContatoEmpresa(data);
    } catch (error) {
      exibirToastError(
        get(
          error,
          'response.data.message',
          'Não encontrei os contatos da empresa',
        ),
      );
    }
  };

  const getAvaliacoes = async () => {
    try {
      const {
        data: { count, data },
      } = await api.getAvaliacoes();

      if (!data.length) {
        return;
      }

      const soma = data
        .map(a => Number(a.media))
        .reduce((acc, cur) => acc + cur);

      setAvaliacao({
        media: soma / count.totalRegistros,
        totalAvaliacoes: count.totalRegistros,
      });
    } catch (error) {
      exibirToastError(
        get(
          error,
          'response.data.message',
          'Não foi possível buscar as avaliações',
        ),
      );
    }
  };

  const getFormasdePagamentoList = async () => {
    try {
      const { data } = await api.buscarFormasPagamento();
      setPagamentos(data);

      dispatch({
        type: 'FORMAS_PAGAMENTO',
        formasPagamento: data,
      });
    } catch (error) {
      exibirToastError(
        get(
          error,
          'response.data.message',
          'Formas de pagamento, não encontrada',
        ),
      );
    }
  };

  const getHorariosFuncionamento = async () => {
    try {
      const { data } = await api.getHorariosFuncionamento();
      setFuncionamento(data);
    } catch (error) {
      exibirToastError(
        get(
          error,
          'response.data.message',
          'Horarios de funcionamento, não encontrada',
        ),
      );
    }
  };

  const getProdutosPorCategoria = async (
    empresasId: number,
    quantidade = 10,
  ) => {
    // setPorPagina(porPagina + quantidade);

    console.log(quantidade);

    const params = {
      quantidade: porPagina,
      ativo: 1,
      embed: 'produtos,grupos-complemento-filtrando',
      funcionamento: 1,
      ordenacao: 'ordem',
      empresasId,
    };

    try {
      const { data } = await api.getProdutosPorCategoria(params);
      setCategorias(data);
      setCategoriasFiltrada(data);
    } catch (error) {
      exibirToastError(
        get(error, 'response.data.message', 'Produtos não encontrados'),
      );
    }
  };

  const setConfiguracao = (data: Empresa) => {
    getEnderecoEmpresa();
    getFormasdePagamentoList();
    getHorariosFuncionamento();
    // getAvaliacoes();
    getProdutosPorCategoria(data.id);
    getContatosEmpresa(data.id);
  };

  const buscarEmpresa = async () => {
    try {
      const { data } = await api.getEstabelecimentoPorIdentificador(identifier);

      const {
        data: { token: tokenEmpresa },
      } = await api.loginParaEmpresa(data.id);

      dispatch({
        type: 'TOKEN_EMPRESA',
        tokenEmpresa,
      });

      dispatch({
        type: 'EMPRESA',
        empresa: data,
      });

      setEmpresa(data);
      setConfiguracao(data);
    } catch (error) {
      exibirToastError(error.response.data.message);
    }
  };

  const filtrarProdutosDoCardapio = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e;

    const categoriaFiltrada = categorias
      .map(categoria => ({
        ...categoria,
        produtos: categoria.produtos.filter(produto =>
          produto.nome.toLowerCase().includes(value.toLowerCase())
            ? produto
            : null,
        ),
      }))
      .filter(item => item.produtos.length);

    setCategoriasFiltrada(categoriaFiltrada);
  };

  useEffect(() => {
    buscarEmpresa();
  }, [identifier]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    autoloadFilesJs();
  }, [empresa]);

  return (
    <>
      {empresa ? (
        <>
          <Header />
          <main style={{ transform: 'none', position: 'relative' }}>
            <Apresentacao
              empresa={empresa}
              avaliacao={avaliacao}
              horariosFuncionamento={funcionamento}
              filtrarProdutosDoCardapio={filtrarProdutosDoCardapio}
            />
            <Menu
              categorias={categorias}
              onClick={() => setCategoriasFiltrada(categorias)}
            />
            <Cardapio
              categorias={categoriasFiltrada}
              atualizarLista={getProdutosPorCategoria}
            />
            {/* <Avaliacoes /> */}
          </main>
          <footer>
            <Footer
              empresa={empresa}
              endereco={endereco}
              categorias={categorias}
              contato={contatoEmpresa}
              formasPagamento={pagamentos}
            />
          </footer>
          <div id="toTop" className="detail_page" />
          <ModalCenter />
        </>
      ) : (
        <LoadSkeletonInicial />
      )}
    </>
  );
};

export default App;
