import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import 'moment/locale/pt-br';
import store from './store';
import App from './App';

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Router>
        <Route exact path="/:identifier">
          <App />
        </Route>
      </Router>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root'),
);
