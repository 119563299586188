import { FC } from 'react';
import { Categoria } from '../../types/Categoria';
import Produtos from './Produto';

interface ItemCategoria {
  categoria: Categoria;
  referencia: (node?: Element | null) => void;
}

const Categorias: FC<ItemCategoria> = ({
  categoria,
  referencia,
}: ItemCategoria) => (
  <section
    ref={referencia}
    id={`section-${categoria.nome.replace(/\s/g, '')}`}
    className="m-portlet m-portlet--tab"
  >
    <h4>{categoria.nome}</h4>
    <div className="row">
      {categoria.produtos.map(produto => (
        <Produtos key={produto.id} produto={produto} />
      ))}
    </div>
  </section>
);

export default Categorias;
