/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, ReactElement, InputHTMLAttributes, FC } from 'react';
import VMasker from 'vanilla-masker';
import getInputPorId from '../../utils/getInputPorId';

type InputProps = InputHTMLAttributes<HTMLInputElement>;

const InputMaskMoney: FC<InputProps> = ({ ...rest }): ReactElement => {
  useEffect(() => {
    VMasker(getInputPorId(rest.id || '')).maskMoney({ unit: 'R$' });
  }, [rest.id]);

  return <input {...rest} />;
};

export default InputMaskMoney;
