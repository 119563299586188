/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, FC, InputHTMLAttributes, ReactElement } from 'react';
import inputMask from 'inputmask';
import getInputPorId from '../../utils/getInputPorId';

type AnchorProps = InputHTMLAttributes<HTMLInputElement>;

const InputMaskCpfCnpj: FC<AnchorProps> = ({ ...rest }): ReactElement => {
  const telefone = () =>
    inputMask({ mask: ['999.999.999-99', '99.999.999/9999-99'] });

  useEffect(() => {
    telefone().mask(getInputPorId(rest.id || ''));
  }, [rest.id]);

  return <input {...rest} />;
};

export default InputMaskCpfCnpj;
