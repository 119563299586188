import { useState, useEffect, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal, { closeModal } from '../../../Components/Modal';
import DetalhesDoItem from './DetalhesDoItem';
import Complementos from './Complementos';
import Loading from '../../../Components/Loading';
import maskMoney from '../../../utils/mask-money';
import { StateRedux } from '../../../store/typeRedux';
import api from '../../../utils/api';
import { Produto, Complemento, Grupo } from '../../../types/Produto';
import alertas, { exibirToastError } from '../../../utils/alertas';
import getElementoPorId from '../../../utils/getElementoPorId';

interface GruposEComplementos {
  itens: Complemento[];
  grupo: Grupo;
}

interface Props {
  produto: Produto;
  produtosDosGrupos: GruposEComplementos[];
}

interface Alertas {
  [key: number]: string;
  message: string;
}

type Etapas = 'complementos' | 'detalhes';

const ModalComplementos = ({
  produto,
  produtosDosGrupos,
}: Props): ReactElement => {
  const [etapa, setEtapa] = useState<Etapas>('detalhes');
  const [observacao, setObservacao] = useState<string>('');
  const [quantidade, setQuantidade] = useState<number>(1);
  const [complementosProduto, setComplementosProduto] = useState<Complemento[]>(
    [],
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [alertasGrupo, setAlertasGrupo] = useState<Alertas[]>([]);

  const { pedidosId: pedidoAtivo, tokenUser } = useSelector(
    (state: StateRedux) => state,
  );
  const dispatch = useDispatch();

  const setarAvisoDeLimiteDeItens = () =>
    setAlertasGrupo(
      produtosDosGrupos.map(({ grupo }) => ({
        [grupo.id]: grupo.nome,
        message: `Selecione ao menos ${
          grupo.minimo > 1 ? `${grupo.minimo} itens` : `${grupo.minimo} item`
        } de ${grupo.nome}`,
      })),
    );

  const removerAvisoDeLimiteDeItens = (grupoId: number) =>
    setAlertasGrupo(alertasGrupo.filter(item => !item[grupoId]));

  const getPedidoAtivo = async (): Promise<number | null> => {
    let pedidosId = pedidoAtivo;

    if (!pedidoAtivo) {
      const {
        data: { id },
      } = await api.criarPedido();

      pedidosId = id;
    }

    dispatch({ type: 'PEDIDO_ATIVO', pedidosId });

    return pedidosId;
  };

  const adicionarProdutoNoCarrinho = async (selecionado: Produto) => {
    try {
      if (!tokenUser) {
        getElementoPorId('login-modal')?.click();
        closeModal(`complemento-produto-${produto.id}`);
        alertas.exibirError(
          'Atenção',
          'Por favor faça login para continuar comprando',
        );
        return;
      }

      const pedidosId = await getPedidoAtivo();

      if (!pedidosId) {
        exibirToastError(
          'Não foi possível adicionar produtos no carrinho, tente novamente mais tarde',
        );

        return;
      }

      const pedido = {
        pedidosId,
        produtosId: selecionado.id,
        nome: selecionado.nome,
        preco: selecionado.preco,
        quantidade,
        observacao,
        complementos: complementosProduto,
      };

      setLoading(true);
      const {
        data: { id },
      } = await api.adicionarAoCarrinho(pedidosId, pedido);

      dispatch({
        type: 'ITENS_NO_CARRINHO',
        pedidosProdutos: id,
      });
      closeModal(`complemento-produto-${produto.id}`);
    } catch (error) {
      if (error.response.data.produtos) {
        alertas.exibirError(
          error.response.data.message,
          error.response.data.produtos.map((p: Produto) => p.nome).join(' '),
        );
      } else {
        alertas.exibirError('Atenção', error.response.data.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const atualizarComplementosPorSelecaoMaior = () => {
    produtosDosGrupos.forEach(({ grupo: { selecaoPreco, id } }) => {
      if (selecaoPreco === 'maior') {
        const maiorPreco = Math.max(
          ...complementosProduto
            .filter(c => c.complementosGrupoId === id)
            .map(c => c.preco),
        );

        const totalProdutos = complementosProduto.filter(
          p => p.complementosGrupoId === id,
        ).length;

        setComplementosProduto(
          complementosProduto.map(p => {
            if (p.complementosGrupoId === id) {
              return {
                ...p,
                preco: maiorPreco / totalProdutos / p.quantidade,
              };
            }

            return p;
          }),
        );
      }
    });
  };

  const incluirComplemento = (complemento: Complemento, qtd: number) => {
    const complementoExiste = complementosProduto.filter(
      a => a.id === complemento.id,
    ).length;

    if (complementoExiste) {
      setComplementosProduto(
        complementosProduto.map(c =>
          c.id === complemento.id ? { ...c, quantidade: qtd } : c,
        ),
      );
    }

    if (!complementoExiste) {
      setComplementosProduto([
        ...complementosProduto,
        { ...complemento, quantidade: 1 },
      ]);
    }
  };

  const removerComplemento = (complemento: Complemento, qtd: number) => {
    const complementoExiste = complementosProduto.filter(
      a => a.id === complemento.id,
    ).length;

    if (complementoExiste && qtd === 0) {
      setComplementosProduto(
        complementosProduto.filter(c => c.id !== complemento.id),
      );

      return;
    }

    if (complementoExiste) {
      setComplementosProduto(
        complementosProduto.map(c =>
          c.id === complemento.id ? { ...c, quantidade: qtd } : c,
        ),
      );
    }
  };

  const gerarSubTotal = () => {
    const totalComplementos = complementosProduto
      .map(comp => comp.quantidade * comp.preco)
      .reduce((acc, cur) => acc + cur, 0);

    return maskMoney(quantidade * (produto.preco + totalComplementos));
  };

  useEffect(() => {
    setarAvisoDeLimiteDeItens();

    setEtapa(!produtosDosGrupos.length ? 'detalhes' : 'complementos');
  }, [produtosDosGrupos]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Modal
        title={etapa === 'complementos' ? 'Complementos' : 'Detalhes'}
        modalId={`complemento-produto-${produto.id}`}
        lg
        onDismiss={() => setComplementosProduto([])}
      >
        <>
          {etapa === 'complementos' && (
            <Complementos
              produtosDosGrupos={produtosDosGrupos}
              incluirComplemento={incluirComplemento}
              removerComplemento={removerComplemento}
              removerAvisoDeLimiteDeItens={removerAvisoDeLimiteDeItens}
            />
          )}

          {etapa === 'detalhes' && (
            <DetalhesDoItem
              produto={produto}
              complementosSelecionados={complementosProduto}
              setObservacao={setObservacao}
              setQuantidade={setQuantidade}
              quantidade={quantidade}
            />
          )}

          <div className="footer mb-mobile">
            <div className="row small-gutters">
              <div className="col-md-12">
                <button
                  type="button"
                  className="btn_1 full-width"
                  onClick={() => {
                    if (alertasGrupo.length) {
                      alertas.exibirError(
                        'Atenção',
                        alertasGrupo.map(alerta => alerta.message).join(' '),
                      );
                      return;
                    }

                    if (etapa === 'complementos') {
                      atualizarComplementosPorSelecaoMaior();
                      setEtapa('detalhes');
                      return;
                    }

                    adicionarProdutoNoCarrinho(produto);
                  }}
                >
                  {etapa === 'complementos'
                    ? 'Próximo'
                    : `Adicionar ${gerarSubTotal()}`}
                </button>
              </div>
            </div>
          </div>
        </>
      </Modal>
      <Loading show={loading} />
    </>
  );
};

export default ModalComplementos;
