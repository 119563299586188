import React, { useState, ReactElement, ChangeEvent } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import jwtDecode from 'jwt-decode';
import FormGroup from '../../Components/FormGroup';
import Icon from '../../Components/Icon';
import AlertInput from '../../Components/AlertInput';
import validarEmail from '../../utils/validarEmail';
import { closeModal } from '../../Components/Modal';
import api from '../../utils/api';
import { exibirToastSucesso, exibirToastError } from '../../utils/alertas';
import { StateRedux } from '../../store/typeRedux';

const Cadastro = (): ReactElement => {
  const [nome, setNome] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [senha, setSenha] = useState<string>('');
  const [confirmarSenha, setConfirmarSenha] = useState<string>('');
  const [mostrarSenha, setMostrarSenha] = useState<boolean>(false);

  const { empresa } = useSelector((state: StateRedux) => state);
  const dispatch = useDispatch();

  const extrairDadosTokenUsuario = async (tokenUser: string) => {
    interface Decode {
      data: {
        empresa: number;
        usuariosId: number;
        nivel: string;
      };
    }

    const decode: Decode = jwtDecode(tokenUser);

    if (!decode) {
      exibirToastError('Por favor faça login novamente');
      return;
    }

    const {
      data: { usuariosId, nivel },
    } = decode;

    dispatch({
      type: 'TOKEN_USER',
      tokenUser,
    });

    dispatch({
      type: 'USER',
      user: {
        usuariosId,
        nivel,
      },
    });

    const {
      data: { id: pedidosId },
    } = await api.buscarPedidoDoUsuario(usuariosId);

    dispatch({
      type: 'PEDIDO_ATIVO',
      pedidosId,
    });
  };

  const isNomeSobrenomeValid = (): boolean => {
    const completo = nome.trim().split(' ');

    if (nome && ((completo.length > 1 && !completo[0]) || !completo[1])) {
      return false;
    }

    return true;
  };

  const isEmailValid = (): boolean =>
    !email || (!!email.length && !!validarEmail(email));

  const isSenhasValidas = (): boolean => {
    if (confirmarSenha && senha !== confirmarSenha) {
      return false;
    }

    return true;
  };

  const isFormularioValido = (): boolean => {
    if (!nome || !isNomeSobrenomeValid()) {
      exibirToastError('Por favor digite seu nome e sobrenome');
      return false;
    }

    if (!email || !isEmailValid()) {
      exibirToastError('E-mail inválido');
      return false;
    }

    if (!senha) {
      exibirToastError('Por favor digite uma senha');
      return false;
    }

    if (senha.length < 8) {
      exibirToastError('A senha deve possuir no mínimo 8 caracteres');
      return false;
    }

    if (!confirmarSenha) {
      exibirToastError('Por favor confirme a senha');
      return false;
    }

    if (!isSenhasValidas()) {
      exibirToastError('Senhas não correspondem');
      return false;
    }

    return true;
  };

  const handlerCreateUser = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isFormularioValido()) {
      return;
    }

    try {
      const newUsuario = {
        nome,
        email,
        senha,
        nivel: 'C',
        newsletter: 1,
        accountId: 0,
        nascimento: moment().format('YYYY-MM-DD'),
        empresasId: empresa?.id,
      };

      await api.adicionarUsuario(newUsuario);
      const {
        data: { token: tokenUser },
      } = await api.loginUsuario({
        email,
        senha,
        nivel: 'C',
        empresasId: empresa?.id || 0,
      });

      extrairDadosTokenUsuario(tokenUser);
      closeModal('modal-cadastro');
      exibirToastSucesso('Cadastro realizado com sucesso!');
    } catch (error) {
      exibirToastError(error.response.data.message);
    }
  };

  return (
    <div
      className="content"
      style={{ backgroundColor: '#FFFFFF', padding: 20 }}
    >
      <form style={{ height: 'fit-content' }} onSubmit={handlerCreateUser}>
        <div className="sign-in-wrapper">
          <FormGroup style={{ marginBottom: 0 }}>
            <span>Nome e sobrenome</span>
            <input
              type="text"
              className="form-control"
              placeholder="Nome e sobrenome"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setNome(e.target.value)
              }
            />
            <Icon className="icon_pencil-edit" />
            <AlertInput
              title="Por favor digite seu nome e sobrenome"
              show={!isNomeSobrenomeValid()}
            />
          </FormGroup>
          <FormGroup style={{ marginBottom: 0 }}>
            <span>E-mail</span>
            <input
              type="email"
              className="form-control"
              placeholder="exemplo@email.com"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
            />
            <Icon className="icon_mail_alt" />
            <AlertInput title="E-mail inválido" show={!isEmailValid()} />
          </FormGroup>
          <FormGroup>
            <span>Senha</span>
            <div
              style={{
                position: 'relative',
                display: 'block',
                verticalAlign: 'baseline',
                margin: 0,
              }}
            >
              <input
                className="form-control"
                type={mostrarSenha ? 'text' : 'password'}
                placeholder="Mínimo 8 caracteres"
                style={{ margin: 0, paddingRight: 51.8438 }}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setSenha(e.target.value)
                }
              />
              <button
                type="button"
                aria-label="Show Password"
                title="Mostrar senha"
                className="my-toggle"
                aria-pressed="false"
                onClick={() => setMostrarSenha(!mostrarSenha)}
                style={{
                  position: 'absolute',
                  right: 0,
                  top: '50%',
                  marginTop: -15,
                }}
              >
                {mostrarSenha ? 'Esconder' : 'Mostrar'}
              </button>
            </div>
            <Icon
              className={mostrarSenha ? 'icon_lock-open_alt' : 'icon_lock_alt'}
            />
          </FormGroup>
          <FormGroup>
            <span>Confirmar senha</span>
            <div
              style={{
                position: 'relative',
                display: 'block',
                verticalAlign: 'baseline',
                margin: 0,
              }}
            >
              <input
                className="form-control"
                type="password"
                placeholder="Confirmar senha"
                style={{ margin: 0, paddingRight: 51.8438 }}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setConfirmarSenha(e.target.value)
                }
              />
            </div>
            <Icon className="icon_lock_alt" />
            <AlertInput
              title="Senhas não correspondem"
              show={!isSenhasValidas()}
            />
          </FormGroup>
          <div className="text-center">
            <input
              type="submit"
              className="btn_1 full-width mb_5"
              defaultValue="Cadastrar"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Cadastro;
