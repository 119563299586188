import { useState, ReactElement } from 'react';
// import { useSelector } from 'react-redux';
import ImagemDoProduto from './Components/ImagemDoProduto';
import maskMoney from '../../utils/mask-money';
import ModalComplementos from './Components/ModalComplementos';
import { Anchor } from '../../Components';
import api from '../../utils/api';
import {
  Produto as TypeProduto,
  Complemento,
  Grupo,
} from '../../types/Produto';
// import { StateRedux } from '../../store/typeRedux';
import { exibirToastError } from '../../utils/alertas';

interface ItemProduto {
  produto: TypeProduto;
}

interface GruposEComplementos {
  itens: Complemento[];
  grupo: Grupo;
}

const Produto = ({ produto }: ItemProduto): ReactElement => {
  // const { user } = useSelector((state: StateRedux) => state);

  const [produtosDosGrupos, setProdutosDosGrupos] = useState<
    GruposEComplementos[]
  >([]);

  const buscarComplementos = async () => {
    try {
      const produtosGrupo = await Promise.all(
        produto.gruposComplemento.map(async grupo => {
          const { data } = await api.getProdutosPorGrupoDeComplementos({
            id: grupo.id,
            quantidade: 999999,
          });

          return { itens: data, grupo };
        }),
      );

      setProdutosDosGrupos(produtosGrupo);
    } catch (error) {
      exibirToastError(error.response.data.message);
    }
  };

  return (
    <>
      <ModalComplementos
        produto={produto}
        produtosDosGrupos={produtosDosGrupos}
      />
      <div
        className="col-md-6"
        style={{ display: 'grid', marginTop: 6, marginBottom: 6 }}
      >
        <Anchor
          className="menu_item modal_dialog box_topic"
          href="#modal-dialog"
          data-toggle="modal"
          data-target={`#complemento-produto-${produto.id}`}
          onClick={buscarComplementos}
          style={{ textAlign: 'unset' }}
        >
          <ImagemDoProduto
            produto={produto}
            style={{
              height: produto.descricao ? '80%' : '70%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          />
          <h3>{produto.nome}</h3>
          <p style={{ marginLeft: 10 }}>{produto.descricao}</p>
          <div className="flex flex-direction-column">
            {produto.precoSemPromocao && (
              <strong style={{ color: '#999', textDecoration: 'line-through' }}>
                {maskMoney(produto.precoSemPromocao)}
              </strong>
            )}
            {produto.precoMinimo && !produto.precoSemPromocao ? (
              <strong>À partir de {maskMoney(produto.precoMinimo)}</strong>
            ) : (
              <strong>{maskMoney(produto.preco)}</strong>
            )}
          </div>
          {/* {user && produto.cashback && (
            <p style={{ color: '#f4516c' }}>
              Volta {maskMoney(produto.cashback)} de Plom Cash
            </p>
          )} */}
        </Anchor>
      </div>
    </>
  );
};

export default Produto;
