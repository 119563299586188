import React, { ReactElement, useState, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import validarEmail from '../../utils/validarEmail';
import FormGroup from '../../Components/FormGroup';
import Loading from '../../Components/Loading';
import Icon from '../../Components/Icon';
import AlertInput from '../../Components/AlertInput';
import { closeModal } from '../../Components/Modal';
import { exibirToastSucesso, exibirToastError } from '../../utils/alertas';
import { StateRedux } from '../../store/typeRedux';
import api from '../../utils/api';

type ShorFormulario = 'ENVIAR' | 'CODIGO' | 'ALTERAR';

const EsqueciSenha = (): ReactElement => {
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [codigo, setCodigo] = useState<string>('');
  const [senha, setSenha] = useState<string>('');
  const [confirmarSenha, setConfirmarSenha] = useState<string>('');
  const [mostrarSenha, setMostrarSenha] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<ShorFormulario>('ENVIAR');
  const { empresa } = useSelector((state: StateRedux) => state);

  const isSenhasValidas = (): boolean => {
    if (confirmarSenha && senha !== confirmarSenha) {
      return false;
    }

    return true;
  };

  const isFormularioValido = (): boolean => {
    if (!senha) {
      exibirToastError('Por favor digite uma senha');
      return false;
    }

    if (senha.length < 8) {
      exibirToastError('A senha deve possuir no mínimo 8 caracteres');
      return false;
    }

    if (!confirmarSenha) {
      exibirToastError('Por favor confirme a senha');
      return false;
    }

    if (!isSenhasValidas()) {
      exibirToastError('Senhas não correspondem');
      return false;
    }

    return true;
  };

  const handlerEnviarEmail = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validarEmail(email)) {
      exibirToastError('E-mail inválido');
      return;
    }

    try {
      setLoading(true);
      await api.redefinirSenha(email, empresa?.id || 0);
      setShowForm('CODIGO');
    } catch (error) {
      exibirToastError(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handlerVerificarCodigo = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setLoading(true);
      await api.verificarCodigoAlterarSenha(email, codigo);
      setShowForm('ALTERAR');
    } catch (error) {
      exibirToastError(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handlerAlterarSenha = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isFormularioValido()) {
      return;
    }

    try {
      setLoading(true);
      const params = {
        email,
        senha,
        empresasId: empresa?.id,
      };
      await api.alterarSenha(params, codigo);
      closeModal('modal-esqueci-senha');
      exibirToastSucesso('Senha alterada com sucesso!');
    } catch (error) {
      exibirToastError(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="content"
      style={{ backgroundColor: '#FFFFFF', padding: 20 }}
    >
      <Loading show={loading} />
      {showForm === 'ENVIAR' && (
        <form style={{ height: 'fit-content' }} onSubmit={handlerEnviarEmail}>
          <div className="sign-in-wrapper">
            <FormGroup style={{ marginBottom: 0 }}>
              <span>E-mail</span>
              <input
                type="email"
                className="form-control"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.target.value)
                }
              />
              <i className="icon_mail_alt" />
              <div
                role="alert"
                className={`fade alert alert-danger ${
                  !email.length || validarEmail(email) ? '' : 'show'
                }`}
              >
                <span style={{ color: '#f4516c' }}>E-mail inválido!</span>
              </div>
            </FormGroup>
            <p>Insira seu email para recuperar sua senha</p>
            <input
              type="submit"
              value="Redefinir senha"
              className="btn_1 full-width mb_5"
            />
          </div>
        </form>
      )}
      {showForm === 'CODIGO' && (
        <form
          style={{ height: 'fit-content' }}
          onSubmit={handlerVerificarCodigo}
        >
          <div className="sign-in-wrapper">
            <FormGroup style={{ marginBottom: 0 }}>
              <span>Código</span>
              <input
                type="text"
                className="form-control"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setCodigo(e.target.value)
                }
              />
              <i className="icon_key_alt" />
              <div role="alert" className="fade alert alert-danger">
                <span style={{ color: 'rgb(244, 81, 108)' }}>
                  E-mail inválido!
                </span>
              </div>
            </FormGroup>
            <p>Enviamos um código para seu email</p>
            <input
              type="submit"
              className="btn_1 full-width mb_5"
              value="Verificar"
            />
          </div>
        </form>
      )}
      {showForm === 'ALTERAR' && (
        <form style={{ height: 'fit-content' }} onSubmit={handlerAlterarSenha}>
          <div className="sign-in-wrapper">
            <FormGroup>
              <span>Senha</span>
              <div
                style={{
                  position: 'relative',
                  display: 'block',
                  verticalAlign: 'baseline',
                  margin: 0,
                }}
              >
                <input
                  className="form-control"
                  type={mostrarSenha ? 'text' : 'password'}
                  placeholder="Mínimo 8 caracteres"
                  style={{ margin: 0, paddingRight: 51.8438 }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setSenha(e.target.value)
                  }
                />
                <button
                  type="button"
                  aria-label="Show Password"
                  title="Mostrar senha"
                  className="my-toggle"
                  aria-pressed="false"
                  onClick={() => setMostrarSenha(!mostrarSenha)}
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: '50%',
                    marginTop: -15,
                  }}
                >
                  {mostrarSenha ? 'Esconder' : 'Mostrar'}
                </button>
              </div>
              <Icon
                className={
                  mostrarSenha ? 'icon_lock-open_alt' : 'icon_lock_alt'
                }
              />
            </FormGroup>
            <FormGroup>
              <span>Confirmar senha</span>
              <div
                style={{
                  position: 'relative',
                  display: 'block',
                  verticalAlign: 'baseline',
                  margin: 0,
                }}
              >
                <input
                  className="form-control"
                  type="password"
                  placeholder="Confirmar senha"
                  style={{ margin: 0, paddingRight: 51.8438 }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setConfirmarSenha(e.target.value)
                  }
                />
              </div>
              <Icon className="icon_lock_alt" />
              <AlertInput
                title="Senhas não correspondem"
                show={!isSenhasValidas()}
              />
            </FormGroup>
            <div className="text-center">
              <input
                type="submit"
                className="btn_1 full-width mb_5"
                value="Alterar"
              />
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default EsqueciSenha;
