import { ReactElement, ReactNode, CSSProperties } from 'react';

interface Props {
  children: ReactNode;
  style?: CSSProperties;
}

const Dropdown = ({ children, style }: Props): ReactElement => (
  <div className="dropdown" style={style}>
    {children}
  </div>
);

Dropdown.defaultProps = {
  style: undefined,
};

export default Dropdown;
