import { toMoney } from 'vanilla-masker';

const removeMaskMoney = (mask: string): number =>
  Number(
    toMoney(mask)
      .replace(/[^0-9,]*/g, '')
      .replace('.', '')
      .replace(',', '.'),
  );

export default removeMaskMoney;
