import { useState, ChangeEvent, ReactElement } from 'react';
import { Modal, closeModal, Anchor, Button } from '../../../Components';
import { FormaPagamento } from '../../../types/Pagamento';
import FormasDePagamento from './FormasDePagamento';
import InputMaskMoney from '../../../Components/Input/InputMaskMoney';
import maskMoney from '../../../utils/mask-money';
import removeMaskMoney from '../../../utils/remove-mask-money';

interface Props {
  formaPagamento: FormaPagamento | null;
  setFormaPagamento: (value: FormaPagamento) => void;
  setTroco: (value: number) => void;
  troco: number;
  valorTotal: number;
  enable: boolean;
}

const SelecaoFormaDePagamento = ({
  formaPagamento,
  setFormaPagamento,
  setTroco,
  troco,
  valorTotal,
  enable,
}: Props): ReactElement => {
  const [errorTroco, setErrorTroco] = useState<boolean>(false);

  return (
    <>
      <div style={{ marginBottom: 10 }}>
        <div
          className="dropdown"
          style={{ pointerEvents: enable ? 'visible' : 'none' }}
        >
          <Anchor
            className="modal-from-cart flex justify-content-space-between"
            data-toggle="modal"
            data-target="#modal-formas-de-pagamento"
            style={{ color: enable ? '' : '#999' }}
          >
            {formaPagamento
              ? `${formaPagamento.nome} - ${formaPagamento.tipo.nome}`
              : 'Formas de pagamento'}
            <i className="icon_currency" />
          </Anchor>
        </div>
        <Anchor
          className="modal-from-cart btn_1 gradient full-width mb_5"
          style={{
            display: formaPagamento?.nome === 'Dinheiro' ? 'block' : 'none',
          }}
          data-toggle="modal"
          data-target="#modal-troco"
        >
          {troco ? `Troco para ${maskMoney(troco)}` : 'Preciso de troco'}
        </Anchor>
      </div>

      <Modal
        title="Formas de pagamento"
        modalId="modal-formas-de-pagamento"
        lg
        style={{ zIndex: 999999999 }}
      >
        <FormasDePagamento setFormaPagamento={setFormaPagamento} />
      </Modal>
      <Modal
        title="Troco para quanto?"
        modalId="modal-troco"
        onDismiss={() => {
          setTroco(troco || 0);
          closeModal('modal-troco');
        }}
        lg
        style={{ zIndex: 999999999 }}
      >
        <div className="main">
          <div className="form-group">
            <span>Seu pedido deu {maskMoney(valorTotal)}</span>
            <InputMaskMoney
              type="text"
              id="troco"
              className="form-control"
              placeholder="R$ 0,00"
              value={maskMoney(troco)}
              onInput={(e: ChangeEvent<HTMLInputElement>) => {
                const value = removeMaskMoney(e.target.value);

                if (value > 0 && value <= valorTotal) {
                  setErrorTroco(true);
                } else {
                  setErrorTroco(false);
                }

                setTroco(value);
              }}
            />
            <div
              role="alert"
              className={`fade alert ${errorTroco ? 'show' : ''}`}
              style={{ marginBlock: errorTroco ? 5 : 0 }}
            >
              <span style={{ color: '#f4516c' }}>
                Valor para troco inválido
              </span>
            </div>
            <Button
              title="Confirmar"
              onClick={() => {
                closeModal('modal-troco');
                setTroco(troco);
              }}
              disabled={troco <= valorTotal}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SelecaoFormaDePagamento;
