import { ReactElement } from 'react';
import ModalLogin from './ModalLogin';
import Login from '../Pages/Login/Login';
import Cadastro from '../Pages/Login/Cadastro';
import EsqueciSenha from '../Pages/Login/EsqueciSenha';

const ModalCenter = (): ReactElement => (
  <>
    <ModalLogin title="Login" modalId="modal-login">
      <Login />
    </ModalLogin>

    <ModalLogin title="Inscreva-se" modalId="modal-cadastro">
      <Cadastro />
    </ModalLogin>

    <ModalLogin title="Esqueceu sua senha?" modalId="modal-esqueci-senha">
      <EsqueciSenha />
    </ModalLogin>
  </>
);

export default ModalCenter;
