/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, ReactElement } from 'react';
import colors from '../../../utils/colors';
import { Complemento, Grupo } from '../../../types/Produto';
import maskMoney from '../../../utils/mask-money';

interface GruposEComplementos {
  itens: Complemento[];
  grupo: Grupo;
}

interface PropsComplemento {
  produtosDosGrupos: GruposEComplementos[];
  incluirComplemento: (complemento: Complemento, quantidade: number) => void;
  removerComplemento: (complemento: Complemento, quantidade: number) => void;
  removerAvisoDeLimiteDeItens: (grupoId: number) => void;
}

interface Quantidade {
  [key: string]: number;
}

const Complementos = ({
  produtosDosGrupos,
  incluirComplemento,
  removerComplemento,
  removerAvisoDeLimiteDeItens,
}: PropsComplemento): ReactElement => {
  const [totalItensPorGrupo, setTotalItensPorGrupo] = useState<Quantidade>({});
  const [quantidades, setQuantidades] = useState<Quantidade>({});

  const limitarPoGrupo = (operacao: 'inc' | 'dec', grupo: Grupo): boolean => {
    const totalGrupo =
      operacao === 'inc'
        ? totalItensPorGrupo[grupo.id] + 1 || 1
        : totalItensPorGrupo[grupo.id] - 1 || 0;

    if (totalGrupo >= grupo.minimo) {
      removerAvisoDeLimiteDeItens(grupo.id);
    }

    if (totalGrupo <= grupo.maximo) {
      setTotalItensPorGrupo({ [grupo.id]: totalGrupo });
      return false;
    }

    return true;
  };

  const adicionarItem = (complemento: Complemento, grupo: Grupo) => {
    const quantidadeAtual = quantidades[complemento.id] + 1 || 1;

    if (limitarPoGrupo('inc', grupo)) {
      return;
    }

    if (quantidadeAtual <= grupo.maximo) {
      setQuantidades({ ...quantidades, [complemento.id]: quantidadeAtual });
      incluirComplemento(complemento, quantidades[complemento.id] + 1);
    }
  };

  const removerItem = (complemento: Complemento, grupo: Grupo) => {
    const quantidadeAtual = quantidades[complemento.id] - 1 || 0;

    limitarPoGrupo('dec', grupo);

    if (quantidadeAtual >= 0) {
      setQuantidades({ ...quantidades, [complemento.id]: quantidadeAtual });
      removerComplemento(complemento, quantidades[complemento.id] - 1);
    }
  };

  return (
    <div className="content" style={{ backgroundColor: '#FFFFFF' }}>
      {produtosDosGrupos.map(({ grupo, itens }) => {
        return (
          <div key={grupo.id}>
            <h4>{grupo.nome}</h4>
            <div
              className="flex justify-content-space-between"
              style={{ marginBottom: 15 }}
            >
              <span style={{ color: colors.gray, fontSize: 13 }}>
                {grupo.minimo === 1 && grupo.maximo === 1
                  ? 'Escolha ao menos 1'
                  : `Escolha ${grupo.minimo ? `de ${grupo.minimo}` : ''} até ${
                      grupo.maximo
                    } opções`}
              </span>
              {grupo.minimo && (
                <span style={{ color: colors.danger, fontSize: 13 }}>
                  Obrigatório
                </span>
              )}
            </div>
            <ul className="clearfix">
              {itens.map(complemento => (
                <li
                  key={complemento.id}
                  className="flex align-items-center justify-content-space-between"
                  style={{ marginBottom: '30px' }}
                >
                  <div className="flex flex-direction-column">
                    <label style={{ width: 180 }}>{complemento.nome}</label>
                    <span style={{ color: '#34bfa3' }}>
                      {' '}
                      + {maskMoney(complemento.preco)}
                    </span>
                  </div>
                  {grupo.minimo === 1 && grupo.maximo === 1 ? (
                    <label className="container_radio">
                      <input
                        type="radio"
                        name="comp"
                        onInput={() => adicionarItem(complemento, grupo)}
                      />
                      <span className="checkmark" />
                    </label>
                  ) : (
                    <div
                      className="numbers-row flex justify-content-center align-items-center"
                      style={{ width: 90, height: 25, border: 'none' }}
                    >
                      <span
                        style={{ fontSize: 18, fontWeight: 500, marginTop: 13 }}
                      >
                        {quantidades[complemento.id] || 0}
                      </span>
                      <a
                        href="#incremento"
                        className="inc button_inc"
                        onClick={() => adicionarItem(complemento, grupo)}
                      >
                        +
                      </a>
                      <a
                        href="#decremento"
                        className="dec button_inc"
                        onClick={() => removerItem(complemento, grupo)}
                      >
                        -
                      </a>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

export default Complementos;
