/* @flow */

const formatarValor = (numero: number | string, prefix = ''): string => {
  let num: number | string =
    typeof numero === 'number' ? numero : Number(numero.replace(',', '.'));

  let x = 0;
  let cents;
  let ret;

  if (num < 0) {
    num = Math.abs(num);
    x = 1;
  }
  if (Number.isNaN(num)) {
    num = 0;
  }
  cents = Math.floor((num * 100 + 0.5) % 100);

  num = Math.floor((num * 100 + 0.5) / 100).toString();
  if (cents < 10) {
    cents = `0${cents}`;
  }
  for (let i = 0; i < Math.floor((num.length - (1 + i)) / 3); i += 1) {
    num = `${num.substring(0, num.length - (4 * i + 3))}.${num.substring(
      num.length - (4 * i + 3),
    )}`;
  }
  ret = `${num},${cents}`;
  if (x === 1) {
    ret = ` - ${ret}`;
  }

  return prefix ? `${prefix} ${ret}` : ret;
};

const maskMoney = (numero: number | string): string =>
  `R$ ${formatarValor(numero)}`;

export default maskMoney;
