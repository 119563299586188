import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { get } from 'lodash';
import IndependentLabel from '../../../Components/IndependentLabel';
import { Dropdown, Modal, closeModal, Anchor } from '../../../Components';
import api from '../../../utils/api';
import { Voucher } from '../../../types/Voucher';
import { exibirToastError, showErrorForDev } from '../../../utils/alertas';
import maskMoney from '../../../utils/mask-money';
import { CONDICAO_VOUCHER } from '../../../utils/constantes';
import { StateRedux } from '../../../store/typeRedux';

interface Props {
  totalPedido: number;
  selectVoucher: (voucher: Voucher | null) => void;
  setLoading: (value: boolean) => void;
  usuariosId: number;
  pedidosId: number | null;
  enable: boolean;
}

const SelecaoVoucher = ({
  totalPedido,
  selectVoucher,
  setLoading,
  usuariosId,
  pedidosId,
  enable,
}: Props): ReactElement => {
  const [vouchers, setVouchers] = useState<Voucher[]>([]);
  const [voucherEscolhido, setvoucherEscolhido] = useState<string>(
    'Cupom de desconto',
  );
  const [codigoVoucher, setCodigoVoucher] = useState<string>('');

  const { tokenUser } = useSelector((state: StateRedux) => state);

  const adicionarCupomPrivado = async () => {
    try {
      if (!pedidosId) {
        showErrorForDev('Não tem pedidosId');
        return;
      }
      setLoading(true);
      const { data } = await api.buscarVoucherPrivado(pedidosId, codigoVoucher);
      selectVoucher(data);
      setvoucherEscolhido(data.codigo);
      closeModal('modal-cupons-desconto');
    } catch (error) {
      exibirToastError(
        get(
          error,
          'response.data.message',
          'Algo deu errado ao adicionar o voucher',
        ),
      );
    } finally {
      setLoading(false);
    }
  };

  const bucarVouchers = async () => {
    try {
      const { data } = await api.buscarVouchers(usuariosId);
      setVouchers(data);
    } catch (error) {
      exibirToastError(
        get(
          error,
          'response.data.message',
          'Algo deu errado ao buscar vouchers',
        ),
      );
    }
  };

  const getLimite = (condicao: string | null): string => {
    let limiteUso = 'sem limite por usuário.';

    if (condicao === CONDICAO_VOUCHER.USO_UNICO) {
      limiteUso = '1 vez por usuario.';
    }

    if (condicao === CONDICAO_VOUCHER.PRIMERIA_COMPRA) {
      limiteUso = '1ª compra.';
    }

    return limiteUso;
  };

  const getDataExpiracao = (dataFinal: string): string => {
    const dataExpiracao = moment(dataFinal);

    const diasDiferenca = dataExpiracao.diff(moment(), 'hours') + 1;
    const isValido = diasDiferenca > 0;

    return isValido
      ? `Expira ${dataExpiracao.fromNow()}`
      : `Expirado ${dataExpiracao.fromNow()}`;
  };

  useEffect(() => {
    if (!pedidosId || !tokenUser) {
      return;
    }

    bucarVouchers();
  }, [usuariosId, pedidosId, tokenUser]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Dropdown
        style={{
          marginBottom: 10,
          pointerEvents: enable ? 'visible' : 'none',
        }}
      >
        <Anchor
          className="modal-from-cart flex justify-content-space-between"
          data-toggle="modal"
          data-target="#modal-cupons-desconto"
          style={{ color: enable ? '' : '#999' }}
        >
          {voucherEscolhido}
          <i className="icon_percent" />
        </Anchor>
      </Dropdown>
      <Modal
        title="Cupons"
        modalId="modal-cupons-desconto"
        lg
        style={{ zIndex: 999999999 }}
      >
        <div className="content" style={{ backgroundColor: '#FFF' }}>
          <div className="radio_select" style={{ marginBlock: 10 }}>
            <ul>
              <li style={{ width: '90%' }}>
                <IndependentLabel association="sem-cupom">
                  <div className="flex align-items-center">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Código do cupom"
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setCodigoVoucher(e.target.value)
                      }
                    />
                    <button
                      className="btn_1 small"
                      type="button"
                      style={{ marginLeft: 10 }}
                      onClick={adicionarCupomPrivado}
                    >
                      Adicionar
                    </button>
                  </div>
                </IndependentLabel>
              </li>
            </ul>
            <ul>
              <li style={{ width: '90%' }}>
                <IndependentLabel
                  association="sem-cupom"
                  onClick={() => {
                    setvoucherEscolhido('Cupom de desconto');
                    selectVoucher(null);
                    closeModal('modal-cupons-desconto');
                  }}
                >
                  Sem cupom
                </IndependentLabel>
              </li>
            </ul>

            {vouchers.map(voucher => (
              <ul key={voucher.codigo}>
                <li style={{ width: '90%' }}>
                  <IndependentLabel
                    association="endereco"
                    style={
                      totalPedido < voucher.valorMinimo
                        ? {
                            backgroundColor: '#f8f8f8',
                            cursor: 'not-allowed',
                          }
                        : { cursor: 'pointer' }
                    }
                    onClick={() => {
                      if (totalPedido < voucher.valorMinimo) {
                        return;
                      }

                      setvoucherEscolhido(voucher.codigo);
                      selectVoucher(voucher);
                      closeModal('modal-cupons-desconto');
                    }}
                  >
                    Cupom de {maskMoney(voucher.valor)}
                    <em style={{ color: 'rgb(76, 104, 223)' }}>
                      {voucher.descricao}
                    </em>
                    <div style={{ marginTop: 10 }}>
                      <em
                        style={{
                          fontSize: 13,
                          color: '#646464',
                          textAlign: 'left',
                        }}
                      >
                        {getDataExpiracao(voucher.dataFinal)}
                      </em>
                      <em
                        style={{
                          color: '#999',
                          textAlign: 'left',
                          marginBottom: 5,
                        }}
                      >
                        - Valido para pedidos de{' '}
                        {maskMoney(voucher.valorMinimo)} (Taxa de entrega não
                        inclusa)
                      </em>
                      <em
                        style={{
                          color: '#999',
                          textAlign: 'left',
                          marginBottom: 5,
                        }}
                      >
                        - Data de validade:{' '}
                        {moment(voucher.dataFinal).format('DD/MM/YYYY HH:mm')}{' '}
                        (horário de Brasília) ou até atingir a quantidade de
                        cupons disponíveis.
                      </em>
                      <em
                        style={{
                          color: '#999',
                          textAlign: 'left',
                          marginBottom: 5,
                        }}
                      >
                        - Limite de uso: {getLimite(voucher.condicao)}
                      </em>
                    </div>
                  </IndependentLabel>
                </li>
              </ul>
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SelecaoVoucher;
