interface Response {
  response: {
    data: {
      message: string;
    };
    status: number;
  };
}

const ExceptionError = (message: string, status: number): Response =>
  Object.assign(new Error(), {
    response: {
      data: {
        message,
      },
      status,
    },
  });

export default ExceptionError;
