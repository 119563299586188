import { ReactElement } from 'react';
import { InView, useInView } from 'react-intersection-observer';
import Categorias from './Categoria';
import { Categoria } from '../../types/Categoria';
import Carrinho from './Carrinho';

interface Props {
  categorias: Categoria[];
  atualizarLista: (a: number, b: number) => void;
}

const Cardapio = ({ categorias, atualizarLista }: Props): ReactElement => {
  /**
   * Usado para verificar quando chega no fim da lista de produtos
   */
  const { ref, inView, entry } = useInView();
  if (inView) {
    // Encerrar o observer
    console.log(entry);
    console.log('Buscar mais dados');
    // atualizarLista(162, 10);
  }

  return (
    <>
      <div className="bg_gray">
        <div className="container margin_detail">
          <section id="section-cardapio" />
          <div className="row">
            <InView className="col-lg-8">
              {!categorias.length ? (
                <h3>Não existem produtos para serem carregados</h3>
              ) : (
                categorias.map((categoria: Categoria) => (
                  <Categorias
                    referencia={ref}
                    key={categoria.id}
                    categoria={categoria}
                  />
                ))
              )}
            </InView>
            <Carrinho />
          </div>
        </div>
      </div>
    </>
  );
};

export default Cardapio;
