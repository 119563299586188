import { ReactElement } from 'react';
import { Categoria } from '../types/Categoria';

interface ItemCategoria {
  categorias: Categoria[];
  onClick: () => void;
}

const Menu = ({ categorias, onClick }: ItemCategoria): ReactElement => (
  <>
    <nav className="secondary_nav sticky_horizontal">
      <div className="container">
        <ul id="navi-menu" className="scrollbar-menu">
          {categorias.map(categoria => (
            <li key={categoria.id}>
              <a
                className="list-group-item list-group-item-action"
                href={`#section-${categoria.nome.replace(/\s/g, '')}`}
                onClick={onClick}
              >
                {categoria.nome}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <span />
    </nav>
  </>
);

export default Menu;
