import { ChangeEvent, ReactElement } from 'react';
// import { useSelector } from 'react-redux';
import ImagemDoProduto from './ImagemDoProduto';
import maskMoney from '../../../utils/mask-money';
// import { StateRedux } from '../../../store/typeRedux';
import { Produto, Complemento } from '../../../types/Produto';

const DetalhesDoItem = ({
  produto,
  complementosSelecionados,
  setObservacao,
  setQuantidade,
  quantidade,
}: {
  produto: Produto;
  complementosSelecionados: Complemento[];
  setObservacao: (value: string) => void;
  setQuantidade: (value: number) => void;
  quantidade: number;
}): ReactElement => {
  // const { user } = useSelector((state: StateRedux) => state);

  return (
    <div className="content" style={{ backgroundColor: '#FFFFFF' }}>
      <ImagemDoProduto
        produto={produto}
        style={{ display: 'flex', justifyContent: 'center' }}
      />
      <div className="flex justify-content-space-between">
        <h5>{produto.nome}</h5>
        <div className="flex">
          {produto.precoSemPromocao && (
            <strong
              style={{
                color: '#999',
                textDecoration: 'line-through',
                marginRight: 10,
              }}
            >
              {maskMoney(produto.precoSemPromocao)}
            </strong>
          )}
          <strong>{maskMoney(produto.preco)}</strong>
        </div>
      </div>

      <p>{produto.descricao}</p>

      {/* {user && produto.cashback && (
        <p style={{ color: '#e54750' }}>
          Ao comprar este produto você ganha de volta{' '}
          {maskMoney(produto.cashback)} de Plom Cash
        </p>
      )} */}

      {complementosSelecionados.map(complemento => (
        <div key={complemento.id}>
          <span>{quantidade * complemento.quantidade}x </span>
          <span>{complemento.nome} </span>
          <span>
            {' '}
            -{' '}
            {maskMoney(
              quantidade * (complemento.quantidade * complemento.preco),
            )}
          </span>
          <br />
        </div>
      ))}

      <div className="form-group" style={{ marginTop: 15 }}>
        <textarea
          className="form-control"
          style={{ height: 69, marginTop: 0, marginBottom: 0 }}
          placeholder="Observações"
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            setObservacao(e.target.value)
          }
        />
      </div>
      <div
        className="flex justify-content-space-between align-items-center"
        style={{
          border: '2px solid #9999994a',
          padding: 6,
          marginBottom: 15,
        }}
      >
        <button
          type="button"
          style={{
            border: 'none',
            outline: 'none',
            backgroundColor: 'transparent',
          }}
          onClick={() => {
            if (quantidade > 1) {
              setQuantidade(quantidade - 1);
            }
          }}
        >
          <i
            className="icon_minus_alt2"
            style={{ color: 'rgb(245, 92, 117)', fontSize: '1.5rem' }}
          />
        </button>

        <span style={{ fontSize: 18, fontWeight: 500 }}>{quantidade}</span>

        <button
          type="button"
          style={{
            border: 'none',
            outline: 'none',
            backgroundColor: 'transparent',
          }}
          onClick={() => setQuantidade(quantidade + 1)}
        >
          <i
            className="icon_plus_alt2"
            style={{ color: 'rgb(102, 204, 102)', fontSize: '1.5rem' }}
          />
        </button>
      </div>
    </div>
  );
};

export default DetalhesDoItem;
