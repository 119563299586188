import { MouseEvent, ReactElement, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { FormaPagamento } from '../../../types/Pagamento';
import { StateRedux } from '../../../store/typeRedux';
import { Anchor, closeModal } from '../../../Components';

interface Props {
  setFormaPagamento: (value: FormaPagamento) => void;
}

interface PagamentosOnlineProps {
  pagamentos: FormaPagamento[];
  setFormaPagamento: (value: FormaPagamento) => void;
}

interface PagamentosEntregaProps {
  pagamentos: FormaPagamento[];
  setFormaPagamento: (value: FormaPagamento) => void;
}

const PagamentosPeloApp = ({
  pagamentos,
  setFormaPagamento,
}: PagamentosOnlineProps) => {
  const { user } = useSelector((state: StateRedux) => state);

  if (!user) {
    return null;
  }

  return (
    <>
      <div>
        <h4>Pague pelo app</h4>
        <ul className="clearfix">
          <li style={{ borderBottom: '1px solid #ededed' }} />
          {pagamentos.map(pagamento => (
            <Fragment key={pagamento.id}>
              <li>
                <Anchor
                  className="flex align-items-center justify-content-space-between"
                  style={{
                    paddingInline: 15,
                    cursor: 'pointer',
                    paddingLeft: 0,
                    position: 'unset',
                  }}
                  onClick={(e: MouseEvent) => {
                    e.preventDefault();
                    setFormaPagamento(pagamento);
                    closeModal('modal-formas-de-pagamento');
                  }}
                >
                  <div className="flex flex-direction-column">
                    <span>{`${pagamento.tipo.nome} - ${pagamento.nome}`}</span>
                    {pagamento.nome === 'Plom Cash' && (
                      <span style={{ color: 'rgb(52, 191, 163)' }}>
                        Saldo de: R$ 15,00
                      </span>
                    )}
                  </div>
                  <img
                    className="img-fluid"
                    src={pagamento.imagem}
                    alt={pagamento.nome}
                    width="44"
                  />
                </Anchor>
              </li>
              <li style={{ borderBottom: '1px solid #ededed' }} />
            </Fragment>
          ))}
        </ul>
      </div>
    </>
  );
};

const PagamentosNaEntrega = ({
  pagamentos,
  setFormaPagamento,
}: PagamentosEntregaProps) => {
  const { user } = useSelector((state: StateRedux) => state);

  if (!user) {
    return null;
  }

  return (
    <>
      <div>
        <h4>Pague na entrega</h4>
        <ul className="clearfix">
          <li style={{ borderBottom: '1px solid #ededed' }} />
          {pagamentos.map(pagamento => (
            <Fragment key={pagamento.id}>
              <li>
                <Anchor
                  className="flex align-items-center justify-content-space-between"
                  style={{
                    paddingInline: 15,
                    cursor: 'pointer',
                    paddingLeft: 0,
                    position: 'unset',
                  }}
                  onClick={(e: MouseEvent) => {
                    e.preventDefault();
                    setFormaPagamento(pagamento);
                    closeModal('modal-formas-de-pagamento');
                  }}
                >
                  <div className="flex flex-direction-column">
                    <span>{`${pagamento.tipo.nome} - ${pagamento.nome}`}</span>
                  </div>
                  <img
                    className="img-fluid"
                    src={pagamento.imagem}
                    alt={pagamento.nome}
                    width="44"
                  />
                </Anchor>
              </li>
              <li style={{ borderBottom: '1px solid #ededed' }} />
            </Fragment>
          ))}
        </ul>
      </div>
    </>
  );
};

const FormasDePagamento = ({ setFormaPagamento }: Props): ReactElement => {
  const { formasPagamento } = useSelector((state: StateRedux) => state);

  const pagamentosOnline = formasPagamento.filter(
    pagamento => pagamento.tipo.nome === 'Online',
  );
  const show = false;

  const pagamentosNaEntrega = formasPagamento.filter(
    pagamento => pagamento.tipo.nome !== 'Online',
  );

  return (
    <>
      <div className="content" style={{ backgroundColor: '#FFFFFF' }}>
        {/* EMBREVE  */}
        {show && pagamentosOnline.length && (
          <PagamentosPeloApp
            pagamentos={pagamentosOnline}
            setFormaPagamento={setFormaPagamento}
          />
        )}

        {pagamentosNaEntrega.length && (
          <PagamentosNaEntrega
            pagamentos={pagamentosNaEntrega}
            setFormaPagamento={setFormaPagamento}
          />
        )}
      </div>
      <div className="footer mb-mobile">
        <div className="row small-gutters">
          <div className="col-md-12">
            <button
              type="button"
              className="btn_1 outline full-width"
              onClick={(e: MouseEvent) => {
                e.preventDefault();
                closeModal('modal-formas-de-pagamento');
              }}
            >
              Voltar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormasDePagamento;
