import { ReactElement, ReactNode } from 'react';

interface Props {
  title: string;
  onClick: () => void;
  disabled?: boolean;
  children?: ReactNode;
}

const Button = ({
  title,
  onClick,
  disabled,
  children,
}: Props): ReactElement => (
  <button
    type="button"
    className="btn_1 gradient full-width mb_5"
    style={{
      backgroundColor: '#c10e0e',
      backgroundImage: disabled
        ? 'linear-gradient(to right, #959392, #f0f0f0)'
        : '',
      pointerEvents: disabled ? 'none' : 'visible',
    }}
    onClick={() => (!disabled ? onClick() : null)}
    disabled={disabled}
  >
    {title}
    {children}
  </button>
);

Button.defaultProps = {
  children: undefined,
  disabled: undefined,
};

export default Button;
